




























import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Title from "@/components/Title.vue";
import { useStore } from "@/store";
import moment from "moment-timezone";
import { UnitList } from "@/services/UnitList";

export default defineComponent({
  name: "SelectMasterTime",
  components: {
    Title
  },
  setup() {
    const store = useStore();
    const radioGroup = ref(null);
    const units: Record<string, any> = ref([]);
    const timezone: Record<string, any> = ref([]);

    onMounted(async () => {
      try {
        units.value = await UnitList(store.state.cityId);
        timezone.value = units.value[0]?.timezone;
      } catch (e) {
        console.log("timezone failed");
      }
    });

    watch(
      () => store.state.selectedWorkTime,
      newValue => {
        if (newValue === null) {
          radioGroup.value = null;
        }
      }
    );

    return { store, radioGroup, timezone };
  },
  methods: {
    readableTimeFormat(time: any) {
      return moment(time, "X")
        .tz(this.getTimezone())
        .format("HH:mm");
    },
    selectTime(time: any) {
      if (this.store.state.selectedWorkTime === time) this.radioGroup = null;
      this.store.selectTime(time);
    },
    getTimezone() {
      return this.timezone.toString() || "Europe/Moscow";
    }
  }
});
