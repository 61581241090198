// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Material+Icons);"]);
// Module
exports.push([module.id, "html{font-size:16px!important}#app{font-family:Avenir,Helvetica,Arial,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-align:center;color:#2c3e50;z-index:99999}#nav{padding:30px}#nav a{font-weight:700;color:#2c3e50}#nav a.router-link-exact-active{color:#42b983}@media(min-width:1904px){.container{max-width:1185px!important}}.v-application--wrap{min-height:auto!important}", ""]);
// Exports
module.exports = exports;
