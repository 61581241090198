import { State, BookingSchedules, Schedule } from "@/Interfaces";
import { findIndexManicure } from "@/common/ReturnWindowsByTimeAndMasters";
import { findManicureAndPedicureBundle } from "@/common/SchedulesModules";

function findSchedule(state: State) {
  const selectedTime: number | null = state.selectedWorkTime;
  const selectedManicureEmployer = state.selectedManicureEmployers;
  const selectedPedicureEmployer = state.selectedPedicureEmployers;
  const windowsOrSchedulesArray = state.windowsAndSchedules.windows.length
    ? state.windowsAndSchedules.windows
    : state.windowsAndSchedules.schedules;

  const correctSchedule = windowsOrSchedulesArray.filter(schedule => {
    const indexManicure = findIndexManicure(schedule, "manicure");
    const indexPedicure = findIndexManicure(schedule, "pedicure");

    const manicureSchedule = schedule[indexManicure];
    const pedicureSchedule = schedule[indexPedicure];

    if (manicureSchedule && manicureSchedule.begin_at !== selectedTime)
      return false;

    if (selectedManicureEmployer && selectedPedicureEmployer) {
      if (
        manicureSchedule?.employer_id === selectedManicureEmployer &&
        pedicureSchedule?.employer_id === selectedPedicureEmployer
      )
        return schedule;
    }

    if (!manicureSchedule && !pedicureSchedule) {
      if (schedule[0].begin_at === selectedTime) {
        return true;
      }
    } else {
      if (selectedManicureEmployer && !selectedPedicureEmployer)
        return (
          manicureSchedule.begin_at === selectedTime &&
          manicureSchedule?.employer_id === selectedManicureEmployer
        );
      if (selectedPedicureEmployer && !selectedManicureEmployer)
        return (
          pedicureSchedule?.employer_id === selectedPedicureEmployer &&
          pedicureSchedule.begin_at === selectedTime
        );
    }
    return false;
  });

  const correctScheduleFlatted: Schedule[] = correctSchedule.flat();
  return correctScheduleFlatted;
}

function flatServicesIds(schedules: Schedule[]): number[] {
  return schedules.reduce((ids: number[], schedule) => {
    const servicesIds = schedule.services.map(service => service.id);
    servicesIds.forEach(serviceId => {
      if (!ids.includes(serviceId)) ids = [...ids, serviceId];
    });
    return ids;
  }, []);
}

function flatWindowsIds(schedules: Schedule[]): number[] {
  return schedules.reduce((ids: number[], schedule) => {
    if (!ids.includes(schedule.id)) ids = [...ids, schedule.id];
    return ids;
  }, []);
}

export function createSchedulesForBooking(
  state: State
): {
  schedules: BookingSchedules[];
  servicesIds: number[];
} {
  let schedules = findSchedule(state);
  schedules = findManicureAndPedicureBundle(schedules, state);
  const servicesIds: number[] = flatServicesIds(schedules) || [];
  /* eslint-disable @typescript-eslint/camelcase */
  const finalSchedules: BookingSchedules[] = schedules.map(schedule => ({
    id: schedule.id,
    service_ids: servicesIds,
    ranges: [schedule.begin_at, schedule.end_at]
  }));
  return {
    schedules: finalSchedules,
    servicesIds
  };
}

export function createWindowsIdsArray(
  state: State
): {
  window_ids: number[];
  servicesIds: number[];
} {
  let schedules = findSchedule(state);
  schedules = findManicureAndPedicureBundle(schedules);
  return {
    window_ids: flatWindowsIds(schedules),
    servicesIds: flatServicesIds(schedules)
  };
}
