<template>
  <v-dialog
    :value="value"
    max-width="600"
    @click:outside="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="headline d-block">
        <div class="text-center">
          У вас уже есть запись
        </div>
      </v-card-title>
      <v-card-text class="d-block">
        <p>
          Вы уже записаны в студию на ближайшее время. Если Вы хотите
          отменить запись, то выберите её из списка и нажмите "Отменить запись".
        </p>
        <div class="text-left" v-if="bookingList.length">
          <v-radio-group v-model="radioGroup">
            <v-list>
              <v-list-item
                v-for="booking in bookingList"
                :key="booking.id"
                class="mt-3"
              >
                <v-radio
                  class="ma-0 pa-0 align-baseline d-flex"
                  :value="booking.id"
                >
                  <template v-slot:label>
                    <div class="d-flex ml-3">
                      <span>{{ bookingDateFormat(booking) }}</span>
                      <div class="ml-5">
                        <strong>{{ bookingTimeFormat(booking) }}</strong
                        ><br />
                        <span>{{ bookingAddress(booking) }}</span
                        ><br />
                        <span>{{ bookingEmployerName(booking) }}</span>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-list-item>
            </v-list>
          </v-radio-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Закрыть
        </v-btn>
        <v-btn
          :disabled="!radioGroup"
          :loading="cancelBookingLoading"
          color="black"
          depressed
          class="white--text"
          @click="cancelBookingLocal"
        >
          Отменить запись
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { getBookingList, cancelBooking } from "@/services/Bookings";
import moment from "moment-timezone";

export default defineComponent({
  name: "ModalBookingAlreadyExist",
  props: ["value"],
  data: () => ({
    bookingList: [],
    selectedBooking: null,
    radioGroup: null,
    cancelBookingLoading: false
  }),
  methods: {
    async cancelBookingLocal() {
      this.cancelBookingLoading = true;
      await cancelBooking(this.radioGroup);
      this.cancelBookingLoading = false;
      /* eslint-disable */
      ym(29736030,'reachGoal','cancelentry')
      /* eslint-enable */
      this.$emit("input", false);
    },
    bookingDateFormat(booking) {
      return moment(booking.begin_at, "YYYY-MM-DD HH:mm:SS").format(
        "DD.MM.YYYY"
      );
    },
    bookingTimeFormat(booking) {
      return moment(booking.begin_at, "YYYY-MM-DD HH:mm:SS").format("HH:mm");
    },
    bookingAddress(booking) {
      return booking.services_with_window[0]?.window.unit.address_short;
    },
    bookingEmployerName(booking) {
      const employer = booking.services_with_window[0].window.employer;
      return `${employer.first_name} ${employer.last_name[0]}.`;
    }
  },
  watch: {
    async value(newVal) {
      if (newVal) {
        this.bookingList = await getBookingList();
      }
    }
  }
});
</script>

<style scoped></style>
