<template>
  <v-dialog :value="value" max-width="600">
    <v-card>
      <v-card-title class="headline">
        Внести предоплату?
      </v-card-title>
      <v-card-text>
        <p>
          В связи с повышенным спросом, с
          {{ computedLocal.prepay.prepayStart }} по
          {{ computedLocal.prepay.prepayEnd }} действует предоплата. В течение
          20 минут необходимо будет внести предоплату. Спасибо за понимание!
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Закрыть
        </v-btn>
        <v-btn
          color="black"
          depressed
          class="white--text"
          @click="$emit('redirect-to-payment')"
        >
          Оформить заявку и внести предоплату
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, defineComponent, reactive } from "@vue/composition-api";
import { useStore } from "@/store";
import moment from "moment-timezone";
import { Prepay } from "@/Interfaces";

export default defineComponent({
  name: "PrepaymentModal",
  props: ["value"],
  setup() {
    const store = useStore();

    const computedLocal = reactive({
      prepay: computed(() => {
        const prepay = store.state?.prepayment;
        if (!prepay.id) return {};
        return {
          prepayStart: moment(prepay?.begin_at, "YYYY-MM-DD").format("DD.MM"),
          prepayEnd: moment(prepay?.end_at, "YYYY-MM-DD").format("DD.MM")
        };
      })
    });

    return { computedLocal };
  }
});
</script>

<style scoped></style>
