




















































































import { defineComponent, reactive, computed } from "@vue/composition-api";
import Vue from "vue";
import VueMask from "v-mask";
import { getCode, confirmation } from "@/services/AuthServices";
import { useStore } from "@/store";
import UserInfo from "@/components/UserInfo.vue";
import AlertModal from "@/components/AlertModal.vue";
import { ERRORS } from "@/config/errors";

Vue.use(VueMask);

export default defineComponent({
  name: "AuthForm",
  setup() {
    const store = useStore();
    const state: any = reactive({
      phone: null,
      userAgree: false,
      showCodeModal: false,
      confirmationCode: "",
      inputError: false,
      getCodeLoading: false,
      bookingModalError: false,
      errorMessages:
        "Что-то пошло не так. Попробуйте повторить попытку позднее или запишитесь через колл-центр",
      confirmCodeLoading: false,
      canGetAuthCode: computed(() => {
        return state.phone && state.userAgree;
      })
    });

    return {
      store,
      state,
      getCode,
      confirmation
    };
  },
  components: {
    UserInfo,
    AlertModal
  },
  methods: {
    phoneFocus() {
      this.state.phone = "+7";
    },
    async getCodeLocal() {
      const phoneNumberLength = 18;
      if (this.state.phone.length !== phoneNumberLength) return;
      try {
        this.state.getCodeLoading = true;
        await getCode(this.state.phone);
        this.state.showCodeModal = true;
        this.state.getCodeLoading = false;
      } catch (e) {
        const code = e.response.data.code as number;
        this.state.bookingModalError = true;
        this.state.errorMessages = ERRORS[code];
      }
    },
    async confirmationLocal() {
      this.state.confirmCodeLoading = true;
      const { code } = await this.store.setTokenAndGetUserInfo(
        this.state.confirmationCode,
        this.state.phone
      );
      this.state.confirmCodeLoading = false;
      if (code === 401) {
        this.state.inputError = true;
      } else {
        this.state.showCodeModal = false;
      }
    }
  }
});
