





































import { computed, defineComponent, reactive } from "@vue/composition-api";
import MapBlock from "@/components/MapBlock.vue";
import ServiceList from "@/components/ServiceList.vue";
import { useState, useStore } from "@/store";
import DatePicker from "@/components/DatePicker.vue";
import { Prepay, State } from "@/Interfaces";
import moment from "moment-timezone";

export default defineComponent({
  name: "First-step",
  setup() {
    const state = useState();
    const store = useStore();
    const getWindows: Function = store.getWindows;

    const data = reactive({
      loading: false
    });

    const computedLocal = reactive({
      prepay: computed(() => {
        const prepay: Prepay = store.state?.prepayment;
        if (!prepay.id) return {};
        return {
          prepayStart: moment(prepay?.begin_at, "YYYY-MM-DD").format("DD.MM"),
          prepayEnd: moment(prepay?.end_at, "YYYY-MM-DD").format("DD.MM")
        };
      })
    });

    const hasSelectedUnitAndServices = computed(() => {
      return (
        state?.unitId && state?.serviceIds.length && state.prepaymentsLoaded
      );
    });

    return {
      getWindows,
      hasSelectedUnitAndServices,
      state,
      computedLocal,
      data
    };
  },
  components: {
    MapBlock,
    ServiceList,
    DatePicker
  },
  methods: {
    async getWindowsLocal() {
      this.data.loading = true;
      await this.getWindows();
      this.data.loading = false;
    }
  }
});
