import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import "./main.scss";

import "./composition-plugin.js";

import Vue from "vue";
import Vuetify from "vuetify/lib";
import axios from "@/common/axios";
import App from "./App.vue";

import { VuetifyThemeVariant } from "vuetify/types/services/theme";
import { initYmap } from "@/common/ymap";
//@ts-ignore
import { initYandexMetrica } from "@/common/YaMetrica";

Vue.use(Vuetify);

interface BookingWidgetInitOptions {
  element: string | HTMLElement;
  authToken?: string;
  cityId?: any;
  theme?: Partial<VuetifyThemeVariant>;
  apiKey?: string;
}

const defaultTheme: VuetifyThemeVariant = {
  primary: "#36afbb",
  secondary: "#363636",
  accent: "#3F51B5",
  error: "#dd3645",
  info: "#33a1b8",
  warning: "#feffc9",
  success: "#1e7e35"
};

const getTheme = (
  theme?: Partial<VuetifyThemeVariant>
): VuetifyThemeVariant => ({ ...defaultTheme, ...theme });

export async function init(options: BookingWidgetInitOptions) {
  const { element, authToken, cityId, apiKey } = options;

  axios.defaults.headers["X-Authorization"] =
    apiKey ?? "42f9bd3c-4418-4747-9f6b-8bf5fb33f422";
  // apiKey ?? "d5342bc8-09a2-49bd-8bbf-8d9edb987196";

  await initYmap();
  initYandexMetrica();

  const vuetify = new Vuetify({
    iconfont: "md",
    theme: {
      themes: {
        light: getTheme(options.theme)
      }
    }
  });

  const isSkuli =
    axios.defaults.headers["X-Authorization"] ===
    "d5342bc8-09a2-49bd-8bbf-8d9edb987196";

  const vm = new Vue({
    vuetify,
    render: h =>
      h(App, {
        props: {
          cityId,
          authToken,
          isSkuli
        }
      })
  });

  vm.$mount(element);

  return () => vm.$destroy();
}
