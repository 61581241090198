// function getUserPosition() {
//     return new Promise<Package>(
//         (
//             resolve: <T><Array<number>>,
//             reject: <T><Array<number>>
//         ) => {
//             if (navigator.geolocation) {
//                 return navigator.geolocation.getCurrentPosition(
//                     (position: Position) => {
//                         return resolve([
//                             position.coords.latitude,
//                             position.coords.longitude
//                         ]);
//                     }
//                 );
//             } else {
//                 const coordsOfSpb: Array<number> = [59.942308, 30.419407];
//                 return reject(coordsOfSpb);
//             }
//         }
//     );
// }

export const getUserPosition = async (): Promise<Array<number>> => {
  const coordsOfSpb: Array<number> = [59.942308, 30.419407];

  return new Promise<Array<number>>((resolve, reject) => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          return resolve([position.coords.latitude, position.coords.longitude]);
        },
        (error: Record<any, any>) => {
          console.log(error);
          return resolve(coordsOfSpb);
        }
      );
    } else {
      return resolve(coordsOfSpb);
    }
  });
};
