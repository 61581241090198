import {
  ManicureAndPedicureEmployersList,
  State,
  uniqWorkTimes
} from "@/Interfaces";

export function filterEmployerByTimeWithParallelState(
  state: State
): {
  manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList;
} {
  const windowsOrSchedulesArray = state.windowsAndSchedules.windows.length
    ? state.windowsAndSchedules.windows
    : state.windowsAndSchedules.schedules;

  const manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList = windowsOrSchedulesArray.reduce(
    (manicureAndPedicureEmployersList, schedule) => {
      return manicureAndPedicureEmployersList;
    },
    {
      manicureEmployers: [],
      pedicureEmployers: []
    } as ManicureAndPedicureEmployersList
  );

  return { manicureAndPedicureEmployersList };
}

export function parallelValue(state: State): number {
  let parallel = 0;
  if (state.serviceCategoryIds.length > 1 && state.isPossibleParallel)
    parallel = 1;
  return parallel;
}
