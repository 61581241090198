<template>
  <div class="service-list">
    <div class="manicure-pedicure-info text-left" v-if="showFourHands">
      Одновременно услуги маникюр и педикюр предоставляются двумя мастерами «в 4
      руки». Стоимость услуги дороже на 500 рублей.
    </div>

    <v-row>
      <v-col
        :cols="store.state.serviceList.length === 1 ? 12 : 6"
        v-for="serviceParent in store.state.serviceList"
        :key="serviceParent.id"
      >
        <div class="mt-3 ml-1">
          <Title :title="serviceParent.title" class="mb-3" />
          <v-checkbox
            class="ma-0 pa-0 custom-checkbox-class"
            v-for="service in serviceParent.services"
            :key="service.id"
            @change="store.setSelectedServiceIds(service.id, serviceParent)"
          >
            <template v-slot:label>
              <span class="check-box-label-custom">{{ service.title }}</span>
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
    <div class="not-found-block text-left mt-3" v-if="!isSkuli">
      <span
        >Не нашли нужную услугу? Обратитесь к специалистам колл-центра по тел.+7
        (812) 640-00-00 или оставьте заявку в
        <a href="http://predzapis.pilkinail.ru/">КАЛЕНДАРЕ МАНИКЮРА</a>
        – мы предложим Вам лучшие варианты записи.</span
      >
    </div>
  </div>
</template>

<script>
import { PEDICURE_SERVICE_ID, MANICURE_SERVICE_ID } from "@/common/Consts";

import { defineComponent } from "@vue/composition-api";
import { useStore } from "@/store";
import Title from "@/components/Title.vue";

export default defineComponent({
  name: "ServiceList",
  data: () => ({
    checkbox: false
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  components: {
    Title
  },
  computed: {
    showFourHands() {
      let hasManicure = false;
      let hasPedicure = false;

      this.store.state.serviceList.forEach(category => {
        if (category?.id === MANICURE_SERVICE_ID) hasManicure = true;
        if (category?.id === PEDICURE_SERVICE_ID) hasPedicure = true;
      });

      return hasManicure && hasPedicure;
    },

    isSkuli() {
      return this.store.hasSkuli();
    }
  }
});
</script>

<style lang="scss">
.not-found-block {
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  a {
    color: black !important;
  }
}

.manicure-pedicure-info {
  color: #636b6f;
  font-size: 14px;
  line-height: 1.6;
}

.service-list {
  .v-input__control {
    height: 30px !important;
  }

  .v-input--selection-controls__input {
    height: 18px;
    width: 18px;

    i {
      background: white;
      border-radius: 2px;
    }
  }
}

.check-box-label-custom {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
}

.input-group__input * {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-input--selection-controls__ripple {
  display: none;
}
</style>
