<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          {{ computedProperties.userAuthenticated.value }}
          <v-text-field
            :background-color="computedProperties.inputBackground"
            label="Имя"
            dense
            hide-details
            outlined
            v-model="store.state.userInfo.firstName"
            class="custom-input"
            :disabled="computedProperties.userAuthenticated"
            required
            :rules="[v => !!v || 'Обязательное поле']"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            :background-color="computedProperties.inputBackground"
            label="Фамилия"
            dense
            outlined
            hide-details
            v-model="store.state.userInfo.lastName"
            class="custom-input"
            :disabled="computedProperties.userAuthenticated"
            required
            :rules="[v => !!v || 'Обязательное поле']"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            :background-color="computedProperties.inputBackground"
            label="Email"
            dense
            outlined
            hide-details
            v-model="store.state.userInfo.email"
            class="custom-input"
            :disabled="computedProperties.userAuthenticated"
            required
            :rules="[v => !!v || 'Обязательное поле']"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      :loading="state.createBookingLoading"
      color="black"
      depressed
      class="white--text mt-2 mb-2"
      @click="createBookingLocal"
      :disabled="!store.state.token"
    >
      Записаться
    </v-btn>
    <v-dialog v-model="state.bookingModal" max-width="600">
      <v-card>
        <v-card-title class="headline d-block">
          <div class="text-center">
            Спасибо
          </div>
        </v-card-title>
        <v-card-text class="d-block">
          <div class="text-left">
            <p>
              Ожидаем вас <strong>{{ state.booking.beginAt }}</strong> в студии
              <strong>{{ state.booking.unitName }}</strong>
            </p>
            <p>
              По адресу <strong>{{ state.booking.address }}</strong>
            </p>
            <p>
              Ваш мастер <strong>{{ state.booking.employer }}</strong>
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            depressed
            class="white--text"
            @click="state.bookingModal = false"
          >
            Хорошо
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alert-modal
      v-model="state.bookingModalError"
      title="Вы не записались"
      message="Что-то пошло не так. Попробуйте повторить попытку позднее или запишитесь через колл-центр."
    />
    <modal-booking-already-exist v-model="state.bookingAlreadyExist" />
    <prepayment-modal
      v-model="state.prepaymentModal"
      @redirect-to-payment="redirectToPayment"
    />
    <a :href="state.newLink" target="_blank" id="new-link" class="d-none">a</a>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from "@vue/composition-api";
import { useStore } from "@/store";
import { createBooking } from "@/services/Bookings";
import moment from "moment-timezone";
import AlertModal from "@/components/AlertModal";
import ModalBookingAlreadyExist from "@/components/ModalBookingAlreadyExist";
import PrepaymentModal from "@/components/PrepaymentModal";

export default defineComponent({
  name: "UserInfo",
  setup() {
    const store = useStore();
    const state = reactive({
      menu: false,
      date: null,
      booking: {
        beginAt: null,
        address: null,
        unitName: null,
        employer: null
      },
      bookingModal: false,
      bookingModalError: false,
      bookingAlreadyExist: false,
      prepaymentModal: false,
      createBookingLoading: false,
      showSuccessCreatedBookingModal: false,
      newLink: null
    });

    const computedProperties = reactive({
      userAuthenticated: computed(() => {
        return !store.state.token;
      }),
      formatBirthDay: computed(() => {
        if (!store.state.userInfo.birthday?.length) return null;
        return moment(
          store.state.userInfo.birthday,
          "YYYY-MM-DD HH:mm:SS"
        ).format("DD.MM.YYYY");
      }),
      inputBackground: computed(() => {
        return store.state.token ? "white" : "";
      })
    });

    return { store, state, computedProperties };
  },
  components: {
    PrepaymentModal,
    AlertModal,
    ModalBookingAlreadyExist
  },
  methods: {
    async redirectToPayment() {
      const { transaction } = await this.createBooking();
      // this.state.newLink = transaction.redirect_url;
      // await this.$nextTick()
      // document.getElementById("new-link").click()
      window.open(transaction.redirect_url, "_blank");
    },
    saveBirthday() {
      this.state.menu = false;
    },
    async createBooking() {
      try {
        this.state.createBookingLoading = true;
        const { booking, transaction } = await createBooking(this.store.state);
        if (this.store.state.prepayment.id) return { booking, transaction };
        const employer = booking.services_with_window[0].window.employer;
        this.state.booking = {
          beginAt: moment(booking.begin_at, "YYYY-MM-DD HH:mm:SS").format(
            "DD.MM HH:mm"
          ),
          address: booking.unit.address,
          unitName: booking.unit.name_show.split(".")[0],
          employer: `${employer.first_name} ${employer.last_name[0]}.`
        };
        this.state.bookingModal = true;
        this.state.createBookingLoading = false;
        /* eslint-disable */
        ym(29736030,'reachGoal','success_lead_form')
        /* eslint-enable */
        this.sendGoogleAnalytics("success", this.store.state.unitId);
        return { booking, transaction };
      } catch (error) {
        this.state.createBookingLoading = false;
        if (error.response.data.code === 1430) {
          this.state.bookingAlreadyExist = true;
          this.sendGoogleAnalytics("exists");
        } else {
          this.state.bookingModalError = true;
          this.sendGoogleAnalytics("fail", this.store.state.unitId);
        }
        throw error;
      }
    },
    sendGoogleAnalytics(type, unitId) {
      unitId
        ? window?.ga("send", "event", "Order", type, unitId)
        : window?.ga("send", "event", "Order");
    },
    async createBookingLocal() {
      if (!this.$refs.form.validate()) return;
      await this.store.updateUser();
      if (this.store.state.prepayment.id) {
        this.state.prepaymentModal = true;
      } else {
        await this.createBooking();
        this.state.showSuccessCreatedBookingModal = true;
      }
    }
  }
});
</script>
