<template>
  <h3 class="text-left black--text text-uppercase title">{{ title }}</h3>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Title",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
});
</script>

<style scoped></style>
