import axios from "@/common/axios";
import { ServiceCategory, Service } from "@/Interfaces";

export async function defaultServiceList(
  cityId: number | null
): Promise<ServiceCategory[]> {
  try {
    const {
      data: { data: items }
    } = await axios.get(`/api/external/v1/services/default?city_id=${cityId}`);
    return items;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function serviceListByUnitId(
  unitId: number
): Promise<ServiceCategory[]> {
  try {
    const {
      data: { data: items }
    } = await axios.get(`/api/external/v1/services/unit/${unitId}`);
    return items;
  } catch (err) {
    console.log(err);
    return [];
  }
}
