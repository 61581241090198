// import { reactive, provide, inject } from "@vue/composition-api";
//
// type Value = number | string | null;
//
// interface Store {
//   unitId: Value;
//   serviceIds: Array<number>;
// }
//
// export const state = {
//   unitId: null,
//   serviceIds: []
// } as Store;
//
// export const stateSymbol = Symbol("state");
// export const createState = () => {
//   return reactive(state);
// };
//
// export const useState = () => inject(stateSymbol);
// export const provideState = () => provide(stateSymbol, createState());
//
// export function setUnitId(value: number): void {
//   state.unitId = value;
// }
//

import {
  reactive,
  provide,
  inject,
  isReadonly,
  shallowReadonly
} from "@vue/composition-api";
import {
  ServiceCategory,
  DateTypeObject,
  State,
  WindowsAndSchedules,
  Schedule,
  UserUpdate,
  User,
  Prepay,
  Employer
} from "@/Interfaces";
import {
  serviceListByUnitId,
  defaultServiceList
} from "@/services/ServiceListService";
import { requestWindows } from "@/services/WindowsService";
import { setOrDeleteItemInArray } from "@/common/setOrDeleteItemInArray";
import {
  createTimeListAndEmployersList,
  filterEmployersByTime,
  filterTimeByEmployer,
  filterEmployersByTimeWithParallel,
  filterEmployerByType
} from "@/common/ReturnWindowsByTimeAndMasters";
// import { filterEmployerByTimeWithParallelState } from "@/common/ParallelState";
import axios from "@/common/axios";
import { confirmation, getUserInfo } from "@/services/AuthServices";
import { updateUserRequest } from "@/services/User";
import { CheckUnitPrepayRequest, Unit } from "@/services/UnitList";
import { parallelValue } from "@/common/ParallelState";
import moment from "moment-timezone";

export function setSelectedServiceIds(state: State, id: number): void {
  state.serviceIds = setOrDeleteItemInArray(state, "serviceIds", id);
}

export function setSelectedCategoryServiceIds(state: State, id: number): void {
  state.serviceCategoryIds = setOrDeleteItemInArray(
    state,
    "serviceCategoryIds",
    id
  );
}

export async function setUnitId(
  state: State,
  value: number
): Promise<ServiceCategory[]> {
  state.unitId = value;
  state.serviceList = await serviceListByUnitId(value);
  return state.serviceList;
}

export async function requestDefaultServiceList(
  state: State
): Promise<ServiceCategory[]> {
  state.serviceList = await defaultServiceList(state.cityId);
  return state.serviceList;
}

export async function getWindowsByParams(
  state: State
): Promise<WindowsAndSchedules> {
  const parallel = parallelValue(state);
  const windows = await requestWindows(
    state.dates,
    state.serviceIds,
    state.unitId,
    parallel
  );
  return windows;
}

export function createTimesAndEmployersLists(
  state: State,
  windowsAndSchedules: WindowsAndSchedules
) {
  const {
    uniqWorkTimes,
    manicureAndPedicureEmployersList,
    parallel
  } = createTimeListAndEmployersList(state, windowsAndSchedules);
  state.uniqWorkTimes = uniqWorkTimes;
  state.manicureAndPedicureEmployersList = manicureAndPedicureEmployersList;
  state.parallel = parallel;
}

export function setDates(state: State, date: DateTypeObject): void {
  state.dates = date;
}

function setBookingType(
  state: State,
  windowsAndSchedules: WindowsAndSchedules
) {
  state.bookingType = windowsAndSchedules.windows.length
    ? "windows"
    : "schedules";
}

async function updateUser(state: State): Promise<User> {
  /* eslint-disable @typescript-eslint/camelcase */
  const userInfo = state.userInfo;
  const params: UserUpdate = {
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    email: userInfo.email,
    birth_date: userInfo.birthday
  };

  const user: User = await updateUserRequest(params);
  return user;
}

function setEmployersLoaded(state: State, value: boolean): void {
  state.employersLoaded = value;
}

function resetEmployers(state: State) {
  state.selectedPedicureEmployers = null;
  state.selectedWorkTime = null;
  state.selectedManicureEmployers = null;
  state.windowsAndSchedules = {
    windows: [],
    schedules: []
  };
  state.manicureAndPedicureEmployersList = {
    manicureEmployers: [],
    pedicureEmployers: []
  };
  setEmployersLoaded(state, false);
}

async function checkUnitPrepay(state: State): Promise<Prepay | undefined> {
  const date = moment(state.dates.beginAt, "YYYY-MM-DD HH:mm:SS").format(
    "YYYY-MM-DD"
  );
  const unitId = state.unitId;
  if (!date || !unitId) return;
  state.prepaymentsLoaded = false;
  const params = {
    date: date,
    unit_id: state.unitId
  };
  state.prepayment = await CheckUnitPrepayRequest(params);
  state.prepaymentsLoaded = true;
  return state.prepayment;
}

export const stateSymbol = Symbol("state");
export const createState = () =>
  reactive<State>({
    serviceIds: [],
    unitId: null,
    serviceList: [],
    dates: {
      beginAt: null,
      endAt: null
    },
    uniqWorkTimes: [],
    selectedWorkTime: null,
    manicureAndPedicureEmployersList: {
      manicureEmployers: [],
      pedicureEmployers: []
    },
    selectedManicureEmployers: null,
    selectedPedicureEmployers: null,
    windowsAndSchedules: {
      windows: [],
      schedules: []
    },
    parallel: false,
    token: "",
    userInfo: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      birthday: ""
    },
    cityId: null,
    bookingType: null,
    employersLoaded: false,
    prepayment: {
      begin_at: "",
      end_at: "",
      id: 0,
      prepay: 0,
      prepay_type: "",
      unit_id: 0
    },
    prepaymentsLoaded: false,
    isPossibleParallel: false,
    serviceCategoryIds: [],
    isSkuli: false
  });

export const useState = () => inject<State>(stateSymbol);

export const provideState = () => provide(stateSymbol, createState());

export const useStore = () => {
  const state = useState();
  if (typeof state === "undefined") {
    throw new Error("state is not provided");
  }
  return {
    state: shallowReadonly(state),
    setSelectedServiceIds(id: number, serviceCategory: ServiceCategory) {
      setSelectedServiceIds(state, id);
      setSelectedCategoryServiceIds(state, serviceCategory.id);
      resetEmployers(state);
    },
    async setUnitId(id: number) {
      await setUnitId(state, id);
      await checkUnitPrepay(state);
      resetEmployers(state);
    },
    async requestDefaultServiceList() {
      await requestDefaultServiceList(state);
    },
    async getWindows() {
      const windows = await getWindowsByParams(state);
      state.windowsAndSchedules = windows;
      createTimesAndEmployersLists(state, windows);
      setBookingType(state, windows);
      setEmployersLoaded(state, true);
    },
    async setDates(date: DateTypeObject) {
      setDates(state, date);
      await checkUnitPrepay(state);
      resetEmployers(state);
    },
    setCityId(id: number) {
      state.cityId = id;
    },
    selectTime(time: number) {
      if (state.selectedWorkTime === time) {
        state.selectedWorkTime = null;
        createTimesAndEmployersLists(state, state.windowsAndSchedules);
        state.selectedManicureEmployers = null;
        state.selectedPedicureEmployers = null;
        return;
      } else {
        state.selectedWorkTime = time;
      }

      if (!parallelValue(state)) {
        const { manicureAndPedicureEmployersList } = filterEmployersByTime(
          state
        );
        state.manicureAndPedicureEmployersList = manicureAndPedicureEmployersList;
      } else {
        const {
          manicureAndPedicureEmployersList
        } = filterEmployersByTimeWithParallel(state);
        state.manicureAndPedicureEmployersList = manicureAndPedicureEmployersList;
      }
    },
    selectEmployer(
      employerId: number,
      employerType: "selectedManicureEmployers" | "selectedPedicureEmployers"
    ) {
      if (state[employerType] === employerId) {
        state[employerType] = null;
        state.selectedWorkTime = null;
        createTimesAndEmployersLists(state, state.windowsAndSchedules);
        return;
      }

      state[employerType] = employerId;
      const parallel = parallelValue(state);

      if (!parallel && !state.selectedWorkTime) {
        const { uniqWorkTimes } = filterTimeByEmployer(state);
        state.uniqWorkTimes = uniqWorkTimes;
      } else if (!state.selectedWorkTime && state.selectedManicureEmployers) {
        const { uniqWorkTimes } = filterTimeByEmployer(state);
        state.uniqWorkTimes = uniqWorkTimes;
      }

      if (employerType === "selectedManicureEmployers" && parallel) {
        state.manicureAndPedicureEmployersList.pedicureEmployers = filterEmployerByType(
          state,
          "manicure"
        );
      } else if (employerType === "selectedPedicureEmployers" && parallel) {
        state.manicureAndPedicureEmployersList.manicureEmployers = filterEmployerByType(
          state,
          "pedicure"
        );
      }
    },
    async setTokenAndGetUserInfo(code: string, phone: string) {
      try {
        const token = await confirmation(code, phone);
        state.token = token;
        axios.defaults.headers["Authorization"] = `Bearer ${token}`;
        /* eslint-disable @typescript-eslint/camelcase */
        const {
          id,
          first_name,
          last_name,
          email,
          birth_date
        } = await getUserInfo();
        state.userInfo = {
          id,
          firstName: first_name,
          lastName: last_name,
          email,
          birthday: birth_date
        };
        return { code: 200 };
      } catch (e) {
        return { code: 401 };
      }
    },
    setCity(id: number) {
      const spbId = 1;
      state.cityId = id || spbId;
    },
    async updateUser() {
      await updateUser(state);
    },
    setEmployersLoaded(value: boolean) {
      setEmployersLoaded(state, value);
    },
    setIsParallelPossible(unit: Unit) {
      state.isPossibleParallel = unit.is_parallel_possible;
    },
    setIsSkuli(value: boolean) {
      state.isSkuli = value;
    },
    hasSkuli() {
      return state.isSkuli;
    }
  };
};
