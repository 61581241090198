<template>
  <div>
    <a href="http://predzapis.pilkinail.ru/">
      <img src="https://pilkinail.ru/booking/img/banner3.jpg" alt="" />
    </a>
    <p class="big-text">
      К сожалению, мы не можем подобрать мастера по заданным параметрам.
      Измените студию, дату или вид услуг, чтобы увидеть другие варианты или
      оставьте Ваш номер телефона. Наш оператор свяжется с Вами в ближайшее
      время.
    </p>
    <div class="align-center d-flex justify-center send-phone">
      <v-text-field
        label="Телефон"
        dense
        outlined
        hide-details
        @focusin="phoneFocus"
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        class="custom-input"
      />
      <div @click="submit" class="custom-button">Отправить</div>
    </div>
    <alert-modal
      v-model="showAlertModal"
      :title="modalTitle"
      :message="modalMessage"
    />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { bookingCallback } from "@/services/Bookings";
import AlertModal from "@/components/AlertModal";
import { deleteSpaces } from "@/common/DeleteSpaces";

export default defineComponent({
  data: () => ({
    phone: null,
    showAlertModal: false,
    modalTitle: "",
    modalMessage: ""
  }),
  components: {
    AlertModal
  },
  methods: {
    setModalMessage(title, message) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.showAlertModal = true;
    },
    phoneFocus() {
      this.phone = "+7";
    },
    async submit() {
      try {
        const phoneWithoutSpaces = deleteSpaces(this.phone);
        await bookingCallback(phoneWithoutSpaces);
        this.setModalMessage(
          "Спасибо",
          "Мы свяжемся с Вами в ближайшее время."
        );
      } catch (error) {
        this.setModalMessage(
          "Что то пошло не так",
          "Не удалось отправить ваш номер. Свяжитесь с нами через колл-центр"
        );
        throw error;
      }
    }
  }
});
</script>

<style lang="scss">
.custom-button {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: #636b6f;
  cursor: pointer;
  margin-left: 20px;
}

.send-phone {
  .v-input {
    max-width: 200px !important;
  }
}

.big-text {
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  color: #12b5bd;
  max-width: 760px;
  margin: 40px auto 0;
}
</style>
