import axios from "@/common/axios";
import { Prepay, PrepayParams } from "@/Interfaces";

export type Unit = {
  address: string;
  address_short: string;
  cashless_pay: boolean;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  phone: string;
  rating_total: string;
  rating_voices: number;
  is_parallel_possible: boolean;
};

export async function UnitList(cityId: number | null): Promise<Unit[]> {
  /* eslint-disable @typescript-eslint/camelcase */
  const {
    data: { data: items }
  } = await axios.get("/api/external/v1/units/city", {
    params: {
      city_id: cityId
    }
  });
  return items;
}

export async function CheckUnitPrepayRequest(
  params: PrepayParams
): Promise<Prepay> {
  const {
    data: { data: prepayments }
  } = await axios.get("/api/prepayment", {
    params
  });
  return prepayments;
}
