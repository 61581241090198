import { Booking, BookingResponse, State } from "@/Interfaces";
import axios from "@/common/axios";
import {
  createSchedulesForBooking,
  createWindowsIdsArray
} from "@/common/Schedules";
import enumerate = Reflect.enumerate;
import moment from "moment-timezone";
import { parallelValue } from "@/common/ParallelState";

export async function createBooking(state: State): Promise<BookingResponse> {
  // @TODO вынести это отсюда нахер
  /* eslint-disable @typescript-eslint/camelcase */
  const params: Booking = {
    unit_id: state.unitId,
    city: state.cityId,
    mobile: false,
    is_aggregator: false,
    waiting_list_booking_id: null,
    user_id: state.userInfo.id,
    booking_type: state.bookingType,
    booking_source_id: 2
  };

  params.parallel = parallelValue(state);

  if (state.bookingType === "schedules") {
    const { schedules, servicesIds } = createSchedulesForBooking(state);
    params.schedules = schedules;
    params.begin_at = state.dates.beginAt;
    params.end_at = state.dates.endAt;
    params.service_ids = servicesIds;
  } else {
    const { window_ids, servicesIds } = createWindowsIdsArray(state);
    params.window_ids = window_ids;
    params.service_ids = servicesIds;
  }

  const {
    data: { data: booking }
  } = await axios.post("/api/external/v1/bookings", params);
  return booking;
}

export async function getBookingList(): Promise<BookingResponse[]> {
  const {
    data: { data: bookings }
  } = await axios.get("/api/external/v1/bookings/undone");
  return bookings;
}

export async function cancelBooking(bookingId: number): Promise<any> {
  const {
    data: { data: result }
  } = await axios.put(`/api/external/v1/bookings/${bookingId}/cancel`, {
    source: "site"
  });
  return result;
}

export async function bookingCallback(phone: string): Promise<string> {
  const { data: result } = await axios.post(
    `https://pilkinail.ru/booking/callback`,
    {
      phone
    }
  );
  return result;
}
