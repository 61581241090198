import {
  State,
  uniqWorkTimes,
  EmployersList,
  WindowsAndSchedules,
  Schedule,
  Window,
  ManicureAndPedicureEmployersList,
  Employer,
  CustomEmployer
} from "@/Interfaces";

import { MANICURE_SERVICE_ID, PEDICURE_SERVICE_ID } from "@/common/Consts";

export function findIndexManicure(window: Schedule[], type: string): number {
  let serviceId: any;
  let findIndexElement;

  if (type === "manicure") serviceId = MANICURE_SERVICE_ID;
  if (type === "pedicure") serviceId = PEDICURE_SERVICE_ID;

  window.forEach((item, index) => {
    const element = item.services.find(
      service => service.service_category_id === serviceId
    );
    if (element) findIndexElement = index;
  });

  if (findIndexElement === 0 || findIndexElement) {
    // @ts-ignore
    return findIndexElement;
  } else {
    return -1;
  }
}

function getInfoFromSchedule(
  schedule: Schedule,
  listEmployers: Employer[]
): {
  masterId: number | null;
  localTime: number;
  indexMasterInMasterList: number;
  name: string;
  typePriceCategory: string;
  rating: number;
  avatar: string;
  hasVaccinated: boolean;
} {
  if (!schedule)
    return {
      indexMasterInMasterList: -1,
      localTime: 0,
      masterId: null,
      name: "",
      typePriceCategory: "",
      rating: 0,
      avatar: "",
      hasVaccinated: false
    };
  const masterId: number = schedule.employer_id;

  return {
    masterId: schedule.employer_id,
    localTime: schedule.begin_at,
    indexMasterInMasterList: listEmployers.findIndex(
      ({ id }) => id === masterId
    ),
    name: `${schedule.first_name} ${schedule.last_name[0]}.`,
    typePriceCategory: `${schedule.type_price_category}`,
    rating: Math.round(parseFloat(schedule.rating) * 100) / 100,
    avatar: schedule.employer_avatar,
    hasVaccinated: schedule.has_vaccinated
  };
}

function createEmployerList(
  schedule: Schedule[],
  listEmployers: Employer[],
  scheduleIndex: number
): Employer[] {
  const currentSchedule: Schedule | Window = schedule[scheduleIndex];

  if (!currentSchedule) return listEmployers;
  const {
    masterId,
    localTime,
    indexMasterInMasterList,
    name,
    typePriceCategory,
    rating,
    avatar,
    hasVaccinated
  } = getInfoFromSchedule(currentSchedule, listEmployers);

  if (indexMasterInMasterList > -1 && localTime) {
    // @ts-ignore
    listEmployers[indexMasterInMasterList].workTimes.push(localTime);
  } else if (masterId && localTime) {
    listEmployers.push({
      id: masterId,
      name: name,
      typePriceCategory: typePriceCategory,
      rating: rating,
      workTimes: [localTime],
      employerAvatar: avatar,
      hasVaccinated: hasVaccinated
    });
  }

  return listEmployers;
}

function filterEmployersByTimeLocal(
  windowsOrSchedulesItemArray: Window[],
  selectedTime: number,
  manicureEmployers: Employer[],
  pedicureEmployers: Employer[]
): {
  manicureEmployers: Employer[];
  pedicureEmployers: Employer[];
} {
  const indexManicure = findIndexManicure(
    windowsOrSchedulesItemArray,
    "manicure"
  );
  const indexPedicure = findIndexManicure(
    windowsOrSchedulesItemArray,
    "pedicure"
  );
  let indexWindow = indexManicure;
  let employerPedicure;

  // if (windowsOrSchedulesItemArray[1].id === 7298137 || windowsOrSchedulesItemArray[0].id === 7298137) {
  //   debugger
  // }

  if (indexManicure === -1 && indexPedicure === -1) indexWindow = 0;
  // if (indexPedicure === -1) indexWindow = 0

  const employerManicure = getInfoFromSchedule(
    windowsOrSchedulesItemArray[indexWindow],
    manicureEmployers
  );

  if (indexPedicure !== -1) {
    employerPedicure = getInfoFromSchedule(
      windowsOrSchedulesItemArray[indexPedicure],
      pedicureEmployers
    );
  }

  const beginH = employerManicure.localTime || employerPedicure?.localTime;

  if (beginH === selectedTime) {
    if (
      employerManicure.masterId &&
      employerManicure?.indexMasterInMasterList === -1
    ) {
      manicureEmployers.push({
        id: employerManicure.masterId,
        name: employerManicure.name,
        rating: employerManicure.rating,
        workTimes: [employerManicure.localTime],
        employerAvatar: employerManicure.avatar
      } as Employer);
    }

    if (
      employerPedicure &&
      employerPedicure.masterId &&
      employerPedicure?.indexMasterInMasterList === -1
    ) {
      pedicureEmployers.push({
        id: employerPedicure.masterId,
        name: employerPedicure.name,
        rating: employerPedicure.rating,
        workTimes: [employerPedicure.localTime],
        employerAvatar: employerPedicure.avatar
      } as Employer);
    }
  }

  return {
    manicureEmployers,
    pedicureEmployers
  };
}

export function filterTimeByEmployer(
  state: State
): { uniqWorkTimes: uniqWorkTimes } {
  const windowsOrSchedulesArray = state.windowsAndSchedules.windows.length
    ? state.windowsAndSchedules.windows
    : state.windowsAndSchedules.schedules;

  const uniqWorkTimes: number[] = windowsOrSchedulesArray.reduce(
    (prevSchedule: number[], currentSchedule: Window[]) => {
      const employerIdManicure = state.selectedManicureEmployers;
      const employerIdPedicure = state.selectedPedicureEmployers;

      let indexManicure = findIndexManicure(currentSchedule, "manicure");
      const indexPedicure = findIndexManicure(currentSchedule, "pedicure");

      if (indexManicure === -1 && indexPedicure === -1) {
        // на бэке нихера еще не заложен род 2 выбранные доп услуги
        indexManicure = 0;
      }

      if (indexPedicure === 0 && indexManicure === -1) {
        indexManicure = indexPedicure;
      }

      const beginAt = currentSchedule[indexManicure].begin_at;
      if (
        (currentSchedule[indexManicure].employer_id === employerIdManicure ||
          employerIdPedicure === currentSchedule[indexManicure].employer_id) &&
        !prevSchedule.includes(beginAt)
      ) {
        if (employerIdPedicure) {
          if (
            currentSchedule[indexPedicure].employer_id === employerIdPedicure
          ) {
            prevSchedule.push(beginAt);
          }
        } else {
          prevSchedule.push(beginAt);
        }
      }
      return prevSchedule;
    },
    []
  );
  return { uniqWorkTimes };
}

export function createWindowsOrSchedulesArray(state: State): Schedule[][] {
  return state.windowsAndSchedules.windows.length
    ? state.windowsAndSchedules.windows
    : state.windowsAndSchedules.schedules;
}

export function filterEmployersByTime(
  state: State
): {
  manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList;
} {
  const windowsOrSchedulesArray = createWindowsOrSchedulesArray(state);

  const manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList = windowsOrSchedulesArray.reduce(
    (manicureAndPedicureEmployersList, currentSchedule) => {
      if (state.selectedWorkTime) {
        const {
          manicureEmployers,
          pedicureEmployers
        } = filterEmployersByTimeLocal(
          currentSchedule,
          state.selectedWorkTime,
          manicureAndPedicureEmployersList.manicureEmployers,
          manicureAndPedicureEmployersList.pedicureEmployers
        );

        manicureAndPedicureEmployersList.manicureEmployers = manicureEmployers;
        manicureAndPedicureEmployersList.pedicureEmployers = pedicureEmployers;
      }

      return manicureAndPedicureEmployersList;
    },
    {
      manicureEmployers: [],
      pedicureEmployers: []
    } as ManicureAndPedicureEmployersList
  );

  return {
    manicureAndPedicureEmployersList
  };
}

export function createTimeListAndEmployersList(
  state: State,
  windowsAndSchedules: WindowsAndSchedules
): {
  uniqWorkTimes: uniqWorkTimes;
  manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList;
  parallel: boolean;
} {
  let parallel = false;
  const uniqWorkTimes: uniqWorkTimes = [];
  const windowsOrSchedulesArray = windowsAndSchedules.windows.length
    ? windowsAndSchedules.windows
    : windowsAndSchedules.schedules;
  const manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList = windowsOrSchedulesArray.reduce(
    (manicureAndPedicureEmployersList, currentSchedule) => {
      let manicureIndex = findIndexManicure(currentSchedule, "manicure");
      const pedicureIndex = findIndexManicure(currentSchedule, "pedicure");
      let uniqWorkTimesIndex = 0;

      if (manicureIndex !== -1) {
        manicureAndPedicureEmployersList.manicureEmployers = createEmployerList(
          currentSchedule,
          manicureAndPedicureEmployersList.manicureEmployers,
          manicureIndex
        );
      }

      if (pedicureIndex !== -1) {
        manicureAndPedicureEmployersList.pedicureEmployers = createEmployerList(
          currentSchedule,
          manicureAndPedicureEmployersList.pedicureEmployers,
          pedicureIndex
        );
      }

      if (manicureIndex === -1 && pedicureIndex === -1) {
        manicureIndex = 0;
        manicureAndPedicureEmployersList.manicureEmployers = createEmployerList(
          currentSchedule,
          manicureAndPedicureEmployersList.manicureEmployers,
          0
        );
      }

      if (!parallel && manicureIndex !== -1 && pedicureIndex !== -1)
        parallel = currentSchedule.length > 1;

      if (manicureIndex !== -1) {
        uniqWorkTimesIndex = manicureIndex;
      }

      if (manicureIndex === -1 && pedicureIndex !== -1) {
        uniqWorkTimesIndex = pedicureIndex;
      }

      if (
        !uniqWorkTimes.includes(currentSchedule[uniqWorkTimesIndex].begin_at)
      ) {
        uniqWorkTimes.push(currentSchedule[uniqWorkTimesIndex].begin_at);
      }

      return manicureAndPedicureEmployersList;
    },
    {
      manicureEmployers: [],
      pedicureEmployers: []
    } as ManicureAndPedicureEmployersList
  );

  uniqWorkTimes.sort();

  return {
    uniqWorkTimes,
    manicureAndPedicureEmployersList,
    parallel
  };
}

function generateCustomEmployer(schedule: Schedule): CustomEmployer {
  return {
    masterId: schedule.employer_id,
    localTime: schedule.begin_at,
    name: `${schedule.first_name} ${schedule.last_name[0]}.`,
    typePriceCategory: `${schedule.type_price_category}`,
    rating: Math.round(parseFloat(schedule.rating) * 100) / 100,
    avatar: schedule.employer_avatar
  };
}

function generateEmployerData(employer: CustomEmployer): Employer {
  return {
    id: employer.masterId,
    name: employer.name,
    rating: employer.rating,
    // @ts-ignore
    workTimes: [employer.localTime],
    employerAvatar: employer.avatar
  };
}

function getEmployerInfoFromSchedule(schedule: Schedule): Employer {
  return {
    id: schedule.employer_id,
    name: `${schedule.first_name} ${schedule.last_name[0]}`,
    typePriceCategory: `${schedule.type_price_category}`,
    rating: Math.round(parseFloat(schedule.rating) * 100) / 100,
    employerAvatar: schedule.employer_avatar
  };
}

function generateEmployersWithParentId(
  windowsOrSchedulesItemArray: Window[]
): {
  manicureEmployer: CustomEmployer;
  pedicureEmployer: CustomEmployer;
} {
  const {
    manicureEmployer,
    pedicureEmployer
  } = windowsOrSchedulesItemArray.reduce(
    (accumulator, schedule) => {
      const serviceManicure = schedule.services.find(
        service => service.service_category_id === MANICURE_SERVICE_ID
      );

      if (serviceManicure) {
        accumulator.manicureEmployer = generateCustomEmployer(schedule);
      } else {
        accumulator.pedicureEmployer = generateCustomEmployer(schedule);
      }

      accumulator.manicureEmployer.parentEmployer =
        accumulator.pedicureEmployer.masterId;
      accumulator.pedicureEmployer.parentEmployer =
        accumulator.manicureEmployer.masterId;

      return accumulator;
    },
    {
      manicureEmployer: {} as CustomEmployer,
      pedicureEmployer: {} as CustomEmployer
    }
  );

  return { manicureEmployer, pedicureEmployer };
}

function filterEmployersByStartTimeWithParallel(
  windowsOrSchedulesItemArray: Window[],
  selectedTime: number,
  manicureEmployers: Employer[],
  pedicureEmployers: Employer[]
): {
  manicureEmployers: Employer[];
  pedicureEmployers: Employer[];
} {
  const { manicureEmployer, pedicureEmployer } = generateEmployersWithParentId(
    windowsOrSchedulesItemArray
  );

  if (manicureEmployer.localTime === selectedTime) {
    const manicureIndex = manicureEmployers.findIndex(
      ({ id }) => id === manicureEmployer.masterId
    );

    const pedicureIndex = pedicureEmployers.findIndex(
      ({ id }) => id === pedicureEmployer.masterId
    );

    if (manicureIndex === -1) {
      manicureEmployers.push(generateEmployerData(manicureEmployer));
    }

    if (pedicureIndex === -1) {
      pedicureEmployers.push(generateEmployerData(pedicureEmployer));
    }
  }

  return {
    manicureEmployers,
    pedicureEmployers
  };
}

export function filterEmployersByTimeWithParallel(
  state: State
): {
  manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList;
} {
  const windowsOrSchedulesArray = createWindowsOrSchedulesArray(state);

  const manicureAndPedicureEmployersList: ManicureAndPedicureEmployersList = windowsOrSchedulesArray.reduce(
    (manicureAndPedicureEmployersList, currentSchedule) => {
      if (state.selectedWorkTime) {
        const {
          manicureEmployers,
          pedicureEmployers
        } = filterEmployersByStartTimeWithParallel(
          currentSchedule,
          state.selectedWorkTime,
          manicureAndPedicureEmployersList.manicureEmployers,
          manicureAndPedicureEmployersList.pedicureEmployers
        );

        manicureAndPedicureEmployersList.manicureEmployers = manicureEmployers;
        manicureAndPedicureEmployersList.pedicureEmployers = pedicureEmployers;
      }

      return manicureAndPedicureEmployersList;
    },
    {
      manicureEmployers: [],
      pedicureEmployers: []
    } as ManicureAndPedicureEmployersList
  );

  return {
    manicureAndPedicureEmployersList
  };
}

export function filterEmployerByType(state: State, type: string): Employer[] {
  const windows = createWindowsOrSchedulesArray(state);
  // @ts-ignore
  return windows.reduce((employers, window) => {
    const indexManicure = findIndexManicure(window, "manicure");
    const indexPedicure = findIndexManicure(window, "pedicure");

    if (type === "manicure") {
      if (
        window[indexManicure].employer_id === state.selectedManicureEmployers
      ) {
        const employerData = getEmployerInfoFromSchedule(window[indexPedicure]);
        const employerInList = employers.find(
          employer => employer.id === employerData.id
        );

        if (state.selectedPedicureEmployers) {
          if (
            window[indexPedicure].employer_id ===
              state.selectedPedicureEmployers &&
            !employerInList
          ) {
            employers.push(employerData);
          }
        } else if (!employerInList) {
          if (
            state.selectedWorkTime &&
            window[indexManicure].begin_at === state.selectedWorkTime
          ) {
            employers.push(employerData);
          } else if (!state.selectedWorkTime) {
            employers.push(employerData);
          }
        }
      }

      return employers;
    }

    if (type === "pedicure") {
      if (
        window[indexPedicure].employer_id === state.selectedPedicureEmployers
      ) {
        const employerData = getEmployerInfoFromSchedule(window[indexManicure]);
        const employerInList = employers.find(
          employer => employer.id === employerData.id
        );

        if (!employerInList) {
          if (
            state.selectedWorkTime &&
            window[indexManicure].begin_at === state.selectedWorkTime
          ) {
            employers.push(employerData);
          }
          if (!state.selectedWorkTime) {
            employers.push(employerData);
          }
        }
      }

      return employers;
    }
  }, [] as Employer[]);
}
