



















import {
  defineComponent,
  provide,
  onMounted,
  reactive,
  computed,
  watch
} from "@vue/composition-api";
import FirstStep from "@/components/FirstStep.vue";
import { createState, stateSymbol, useStore } from "@/store";
import SelectMasterOrTime from "@/components/SelectMasterOrTime/SelectMasterOrTime.vue";
import AuthForm from "@/components/AuthForm.vue";

function scrollTo(elementName: string) {
  const $el = document.getElementById(elementName);
  if ($el) $el.scrollIntoView({ block: "center", behavior: "smooth" });
}

export default defineComponent({
  setup(_, { root }) {
    provide(stateSymbol, createState());
    const store = useStore();

    const computedProperties = reactive({
      showSelectEmployersAndTime: computed(() => {
        return store.state.employersLoaded;
      }),
      showEnterPhone: computed(() => {
        const {
          employersLoaded,
          selectedWorkTime,
          parallel,
          selectedManicureEmployers,
          selectedPedicureEmployers
        } = store.state;
        const loadedAndSelectedWorkTime = employersLoaded && selectedWorkTime;

        if (employersLoaded && selectedWorkTime && parallel)
          return selectedPedicureEmployers && selectedManicureEmployers;
        if (loadedAndSelectedWorkTime && selectedManicureEmployers) return true;
        if (loadedAndSelectedWorkTime && selectedPedicureEmployers) return true;

        return false;
      })
    });

    watch(
      () => computedProperties.showSelectEmployersAndTime,
      async () => {
        await root.$nextTick();
        scrollTo("master-and-time");
      }
    );

    watch(
      () => computedProperties.showEnterPhone,
      async () => {
        await root.$nextTick();
        scrollTo("auth-form");
      }
    );

    onMounted(async () => {
      await store.requestDefaultServiceList();
    });

    return { store, computedProperties };
  },
  props: ["cityId", "isSkuli"],
  mounted() {
    this.store.setCity(this.$props.cityId);
    this.store.setIsSkuli(this.isSkuli);
  },
  components: {
    FirstStep,
    SelectMasterOrTime,
    AuthForm
  }
});
