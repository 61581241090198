import { Schedule, State } from "@/Interfaces";

import {
  MANICURE_SERVICE_ID,
  PEDICURE_SERVICE_ID,
  SCULES_SERVICE_ID
} from "@/common/Consts";

export function findSculesSchedule(
  schedules: Schedule[],
  state: State | null = null
): Schedule[] | undefined {
  return schedules.filter(schedule => {
    return (
      schedule.employer_id === state?.selectedManicureEmployers &&
      schedule.begin_at === state?.selectedWorkTime &&
      schedule.services.find(
        service => service.service_category_id === SCULES_SERVICE_ID
      )
    );
  });
}

export function findManicureSchedule(
  schedules: Schedule[]
): Schedule | undefined {
  return schedules.find(schedule => {
    return schedule.services.find(
      service => service.service_category_id === MANICURE_SERVICE_ID
    );
  });
}

export function findPedicureSchedules(schedules: Schedule[]): Schedule[] {
  return schedules.filter(schedule => {
    return schedule.services.find(
      service => service.service_category_id === PEDICURE_SERVICE_ID
    );
  });
}

export function sortSchedulesByTime(schedules: Schedule[]) {
  return schedules.sort((scheduleFirst, scheduleSecond) => {
    if (scheduleFirst.begin_at < scheduleSecond.begin_at) return 1;
    if (scheduleFirst.begin_at > scheduleSecond.begin_at) return -1;
    return 0;
  });
}

export function findManicureAndPedicureBundle(
  schedules: Schedule[],
  state: State | null = null
): Schedule[] {
  if (schedules.length <= 2) return schedules;

  const sculeSchedule = findSculesSchedule(schedules, state);
  if (sculeSchedule !== undefined) {
    console.log("sculeSchedule is", sculeSchedule);
    return sculeSchedule;
  }

  const manicureSchedule = findManicureSchedule(schedules);
  if (!manicureSchedule) return schedules;

  const pedicureSchedules = findPedicureSchedules(schedules);
  const pedicureSchedulesSortByTime = sortSchedulesByTime(pedicureSchedules);

  return [manicureSchedule, pedicureSchedulesSortByTime[0]];
}
