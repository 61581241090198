import { State } from "@/Interfaces";

export function setOrDeleteItemInArray(
  state: State,
  stateName: "serviceIds" | "serviceCategoryIds",
  value: number
): number[] {
  const itemsArray: number[] = state[stateName];
  if (itemsArray.includes(value)) {
    const index: number = itemsArray.findIndex(
      (serviceId: number): boolean => serviceId === value
    );
    itemsArray.splice(index, 1);
  } else {
    itemsArray.push(value);
  }

  return itemsArray;
}
