<template>
  <p class="text-left">
    Выберите удобное время и мастера.<br />
    Будьте внимательны: Стоимость услуги топ-мастера выше на 30%.
  </p>
</template>

<script>
export default {
  name: "AttentionText",
  props: ["opacity"]
};
</script>
