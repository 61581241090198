import { Service } from "@/Interfaces";
import axios from "@/common/axios";
import {
  DateTypeObject,
  ServiceIdsType,
  WindowsAndSchedules
} from "@/Interfaces";
/* eslint-disable */
export async function requestWindows(dates: DateTypeObject, service_ids: ServiceIdsType, unit_ids: number | null, parallel: number): Promise<WindowsAndSchedules> {
  const { beginAt, endAt } = dates
  try {
    const {
      data: { data: items }
    } = await axios.get("/api/external/v1/windows", {
      params: {
        begin_at: beginAt,
        end_at: endAt,
        service_ids,
        unit_ids: [ unit_ids ],
        parallel
      }
    });
    return items;
  } catch (err) {
    return {
      windows: [],
      schedules: []
    };
  }
}
