<template>
  <div>
    <template v-if="computedValues.hasManicureEmployers">
      <Title title="Мастера" class="title" />
      <v-row>
        <v-col
          v-for="employer in computedValues.manicureEmployers"
          :key="employer.id"
          cols="12"
          class="py-0 mt-1"
          sm="6"
        >
          <employer-block
            employer-type="manicure"
            :selected-master="state.selectedManicureEmployers === employer.id"
            :employer="employer"
          />
        </v-col>
      </v-row>
    </template>

    <template v-if="computedValues.hasPedicureEmployers">
      <Title title="Мастера педикюра" />
      <v-row>
        <v-col
          v-for="employer in computedValues.pedicureEmployers"
          :key="employer.id"
          cols="12"
          class="py-0 mt-1"
          sm="6"
        >
          <employer-block
            employer-type="pedicure"
            :selected-master="state.selectedPedicureEmployers === employer.id"
            :employer="employer"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from "@vue/composition-api";
import Title from "@/components/Title.vue";
import EmployerBlock from "@/components/SelectMasterOrTime/EmployerBlock.vue";
import { useState, useStore } from "@/store";

export default defineComponent({
  name: "SelectMaster",
  components: {
    Title,
    EmployerBlock
  },
  setup() {
    const store = useStore();
    const computedValues = reactive({
      manicureEmployers: computed(() => {
        return store.state.manicureAndPedicureEmployersList.manicureEmployers;
      }),
      pedicureEmployers: computed(() => {
        return store.state.manicureAndPedicureEmployersList.pedicureEmployers;
      }),
      hasManicureEmployers: computed(() => {
        return computedValues.manicureEmployers.length;
      }),
      hasPedicureEmployers: computed(() => {
        return computedValues.pedicureEmployers.length;
      })
    });
    return { state: store.state, computedValues };
  },
  methods: {
    selectEmployer() {
      this.store.selectEmployer(this.employer.id);
    }
  }
});
</script>

<style scoped>
.title {
  margin-bottom: 10px;
}
</style>
