import axios from "@/common/axios";

export async function getCode(phone: string): Promise<string | undefined> {
  const {
    data: { data: code }
  } = await axios.post("/api/external/v1/auth/get_code", {
    phone
  });
  return code;
}

export async function confirmation(
  code: string,
  phone: string
): Promise<string> {
  try {
    const {
      data: {
        data: { token }
      }
    } = await axios.post("/api/external/v1/auth/login", {
      code,
      phone
    });
    return token;
  } catch (err) {
    console.log(err);
    return "";
  }
}

export async function getUserInfo(): Promise<Record<any, any>> {
  const {
    data: { data: data }
  } = await axios.get("/api/external/v1/users/iam");
  return data;
}
