
































import { defineComponent } from "@vue/composition-api";
import { useStore } from "@/store";

const DEFAULT_AVATAR =
  "https://cdn.pilki.com/images/jxgLNc4FwgjFbsFmC2e3UNQBFwUJ8epZp2oYR9K5.png";

export default defineComponent({
  name: "MasterBlock",
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    employer: {
      type: Object,
      default: () => ({})
    },
    selectedMaster: {
      type: Boolean,
      default: false
    },
    employerType: {
      type: String,
      default: "manicure"
    }
  },
  computed: {
    employerAvatar(): string {
      return this.employer.employerAvatar
        ? `https://cdn.pilki.com/${this.employer.employerAvatar}`
        : DEFAULT_AVATAR;
    }
  },
  methods: {
    selectEmployer() {
      const employerType =
        this.employerType === "manicure"
          ? "selectedManicureEmployers"
          : "selectedPedicureEmployers";
      this.store.selectEmployer(this.employer.id, employerType);
    }
  }
});
