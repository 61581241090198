<template>
  <div id="master-and-time">
    <v-row v-if="computedLocal.hasWindowsOrSchedules">
      <v-col cols="12" lg="4">
        <select-master-time />
      </v-col>
      <v-col cols="12" lg="8">
        <v-row>
          <v-col cols="12">
            <select-master />
          </v-col>
          <v-col cols="12" v-if="!isSkuli">
            <attention-text />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <empty-windows-block v-else />
  </div>
</template>

<script>
import SelectMasterTime from "@/components/SelectMasterOrTime/SelectMasterTime.vue";
import SelectMaster from "@/components/SelectMasterOrTime/SelectMaster.vue";
import AttentionText from "@/components/AttentionText";
import EmptyWindowsBlock from "@/components/EmptyWindowsBlock";

import { defineComponent, reactive, computed } from "@vue/composition-api";
import { useStore } from "@/store";

export default defineComponent({
  name: "SelectMasterOrTime",
  components: {
    SelectMasterTime,
    SelectMaster,
    AttentionText,
    EmptyWindowsBlock
  },
  setup() {
    const store = useStore();
    const computedLocal = reactive({
      hasWindowsOrSchedules: computed(() => {
        return (
          store.state.manicureAndPedicureEmployersList.manicureEmployers
            .length ||
          store.state.manicureAndPedicureEmployersList.pedicureEmployers.length
        );
      })
    });
    return { store, state: store.state, computedLocal };
  },
  computed: {
    isSkuli() {
      console.log(this.store.hasSkuli());
      return this.store.hasSkuli();
    }
  }
});
</script>

<style scoped></style>
