


















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { currentDate } from "@/common/currentDate";
import { useStore } from "@/store";
import moment from "moment-timezone";
import { DateTypeObject } from "@/Interfaces";
import Title from "@/components/Title.vue";

const SERVER_FORMAT = "YYYY-MM-DD HH:mm:ss";
const VUETIFY_FORMAT = "YYYY-MM-DD";

export default defineComponent({
  name: "DatePicker",
  setup() {
    const minDate: string = currentDate("YYYY-MM-DD");
    const date: string = currentDate("YYYY-MM-DD");
    const store = useStore();
    // const localState = reactive({
    //   isMobile: false
    // })
    const setDate = (date: DateTypeObject) => store.setDates(date);

    // onMounted(() => {
    //   window.addEventListener("resize", root.onResize, { passive: true })
    // });

    return { minDate, date, store, setDate };
  },
  mounted() {
    this.setDateLocal();
  },
  methods: {
    // onResize() {
    //   this.isMobile = window.innerWidth < 600
    // },
    setDateLocal() {
      const isToday: boolean = moment().format(VUETIFY_FORMAT) === this.date;
      const selectedDate = moment(this.date, VUETIFY_FORMAT);
      const date: DateTypeObject = {
        beginAt: null,
        endAt: null
      };

      date.beginAt = isToday
        ? moment().format(SERVER_FORMAT)
        : selectedDate.startOf("day").format(SERVER_FORMAT);

      date.endAt = selectedDate.endOf("day").format(SERVER_FORMAT);
      this.setDate(date);
    }
  },
  components: {
    Title
  }
});
