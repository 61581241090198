<template>
  <v-dialog
    :value="value"
    max-width="600"
    @click:outside="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="headline d-block">
        <div class="text-center">
          {{ title }}
        </div>
      </v-card-title>
      <v-card-text class="d-block">
        <div class="text-left">
          <p>
            {{ message }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          depressed
          class="white--text"
          @click="$emit('input', false)"
        >
          Хорошо
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BookingModalError",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
