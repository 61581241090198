






























































import {
  defineComponent,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";
import { Unit, UnitList } from "@/services/UnitList.ts";
import { useStore } from "@/store";
import { getUserPosition } from "@/common/getUserPosition";
import Title from "@/components/Title.vue";
import axios from "@/common/axios";
import { UnitFound } from "@/Interfaces";
// import { yandexMap, ymapMarker } from "vue-yandex-maps";

declare global {
  interface Window {
    ymaps: any;
  }
}

export default defineComponent({
  name: "MapBlock",
  data() {
    return {
      unit: {} as Unit,
      dialog: false
    };
  },
  setup() {
    const coords: Record<string, any> = ref([]);
    const units: Record<string, any> = ref([]);
    const store = useStore();
    const localState = reactive({
      mapReady: false,
      myMap: null
    });

    onMounted(async () => {
      try {
        coords.value = await getUserPosition();
        units.value = await UnitList(store.state.cityId);
        axios.defaults.headers["X-Timezone"] = units.value[0]?.timezone;

        if (units.value.length === 1) {
          const unit: Unit = units.value[0];
          await store.setUnitId(unit.id);
          await store.setIsParallelPossible(unit);
        }
      } catch (e) {
        console.log("coords failed");
      }
    });

    return {
      localState,
      store,
      coords,
      units
    };
  },
  computed: {
    PaymentMessage() {
      if (!this.units) return;
      const unitFound: UnitFound = this.units.find(
        (element: Record<string, any>) => element.id === this.store.state.unitId
      );
      return (
        unitFound?.payment_message ||
        "В данной студии не принимается оплата картами, только наличный расчет."
      );
    }
  },
  async mounted() {
    window.ymaps.ready(this.init);
  },
  methods: {
    init() {
      this.localState.myMap = new window.ymaps.Map("map", {
        center: [55.76, 37.64],
        zoom: 15
      });
      this.setMarkers();
    },
    setUnitIdLocal(unit: any) {
      if (!unit.cashless_pay) this.dialog = true;

      this.store.setUnitId(unit.id);
      this.store.setIsParallelPossible(unit);
    },
    setMarkers() {
      if (!this.units.length) return;
      const placemarks: any = [];

      this.units.forEach((unit: any) => {
        // @ts-ignore
        const placemark = new window.ymaps.Placemark(
          [unit.latitude, unit.longitude],
          {
            id: unit.id,
            cashlessPay: unit.cashless_pay,
            isParallelPossible: unit.is_parallel_possible,
            balloonContent: unit.name
          },
          {
            iconLayout: "default#image",
            iconImageHref: "https://pilkinail.ru/booking/img/placemark.png",
            iconImageSize: [48, 48],
            iconImageOffset: [-24, -48]
          }
        );

        placemarks.push(placemark);
      });

      // @ts-ignore
      this.localState.myMap.geoObjects.events.add("click", e => {
        this.setUnitIdLocal({
          id: e.get("target")["properties"].get("id"),
          // eslint-disable-next-line @typescript-eslint/camelcase
          cashless_pay: e.get("target")["properties"].get("cashlessPay"),
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_parallel_possible: e
            .get("target")
            ["properties"].get("isParallelPossible")
        });
      });
      // @ts-ignore
      window.ymaps
        .geoQuery(placemarks)
        .addToMap(this.localState.myMap)
        .applyBoundsToMap(this.localState.myMap);
      // @ts-ignore
      const zoom = this.localState.myMap.getZoom();
      if (zoom && zoom > 15)
        // @ts-ignore
        this.localState.myMap.setZoom(15);
    }
  },
  components: {
    Title
  },
  watch: {
    units() {
      if (!this.localState.myMap) return;
      this.setMarkers();
    }
  }
});
